import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCb3X7tUaCTCWTcYQFM8s4te9wPbo3nhuI',
  authDomain: 'dolchat-martian.firebaseapp.com',
  projectId: 'dolchat-martian',
  storageBucket: 'dolchat-martian.appspot.com',
  messagingSenderId: '675133093173',
  appId: '1:675133093173:web:40e234fd2f0fae8efc487c',
  measurementId: 'G-YGLZNL3YXY',
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const toDoc = (data: any) => ({
  ...JSON.parse(
    JSON.stringify({
      ...data,
    })
  ),
  nodeEnv: process.env.NODE_ENV,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
});
