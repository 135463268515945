import { Types } from 'ably';
// import blackpinkJennie from '../images/avatar-blackpink-jennie.png';
// import blackpinkJisoo from '../images/avatar-blackpink-jisoo.png';
// import blackpinkLisa from '../images/avatar-blackpink-lisa.png';
// import blackpinkRose from '../images/avatar-blackpink-rose.png';
import btsJhope from '../images/avatar-bts-jhope.png';
import btsJimin from '../images/avatar-bts-jimin.png';
import btsJin from '../images/avatar-bts-jin.png';
import btsJungkook from '../images/avatar-bts-jungkook.png';
import btsRm from '../images/avatar-bts-rm.png';
import btsSuga from '../images/avatar-bts-suga.png';
import btsV from '../images/avatar-bts-v.png';
import defaultAvatar from '../images/avatar-default.png';

export const guest = {
  id: 'guest',
  name: 'GUEST',
  avatar: defaultAvatar,
};

export const characters = [
  // { id: 'blackpinkJennie', name: 'JENNIE', avatar: blackpinkJennie },
  // { id: 'blackpinkJisoo', name: 'JISOO', avatar: blackpinkJisoo },
  // { id: 'blackpinkLisa', name: 'LISA', avatar: blackpinkLisa },
  // { id: 'blackpinkRose', name: 'ROSE', avatar: blackpinkRose },
  { id: 'btsJhope', name: 'J-HOPE', avatar: btsJhope },
  { id: 'btsJimin', name: 'JIMIN', avatar: btsJimin },
  { id: 'btsJin', name: 'JIN', avatar: btsJin },
  { id: 'btsJungkook', name: 'JUNGKOOK', avatar: btsJungkook },
  { id: 'btsRm', name: 'RM', avatar: btsRm },
  { id: 'btsSuga', name: 'SUGA', avatar: btsSuga },
  { id: 'btsV', name: 'V', avatar: btsV },
];

export const findCharacter = (id: string) => {
  return characters.find((character) => id === character.id) ?? guest;
};

export const myCharacter = (members: Types.PresenceMessage[]) => {
  const existingCharacters = members.map((member) => member.data.character);
  const ids = characters
    .filter((character) => !existingCharacters.includes(character.id))
    .map((character) => character.id);
  return ids.length > 0
    ? ids[Math.floor(Math.random() * ids.length)]
    : guest.id;
};
