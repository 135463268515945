import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../i18n';

export const UrlCopyButton = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);

  return (
    <button
      className="my-4 p-2 border rounded-xl text-gray-500"
      onClick={() => {
        copy(window.location.href);
        setCopied(true);
      }}
    >
      {copied ? t(i18nKeys.COPIED) : t(i18nKeys.COPY_LINK_TO_CLIPBOARD)}
    </button>
  );
};
