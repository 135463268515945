import React, { FC } from 'react';
import { SystemMessage } from '../../models/SystemMessage';

interface SystemMessageItemProps {
  systemMessage: SystemMessage;
}

export const SystemMessageItem: FC<SystemMessageItemProps> = ({
  systemMessage,
}) => {
  return (
    <div className="flex items-center my-2">
      <hr className="flex-1" />
      <div className="mx-4 font-semibold text-xs text-gray-400">
        {systemMessage.text}
      </div>
      <hr className="flex-1" />
    </div>
  );
};
