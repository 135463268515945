import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import Axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../i18n';
import { Button } from './Button';
import { TextArea } from './TextArea';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
}

export const FeedbackDialog: FC<FeedbackDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [text, setText] = useState('');
  useEffect(() => {
    if (open) {
      setSent(false);
    }
  }, [open]);

  return (
    <DialogOverlay
      className="flex justify-center items-center"
      isOpen={open}
      onDismiss={onClose}
    >
      <DialogContent
        className="flex flex-col m-4 w-full max-w-lg rounded-lg"
        aria-label="Send feedback"
      >
        {sent ? (
          <>
            <div className="py-10">
              <p className="text-center font-bold text-3xl">
                {t(i18nKeys.THANK_YOU_FOR_YOUR_FEEDBACK)}
              </p>
            </div>
            <Button text={i18nKeys.OK} onClick={onClose} />
          </>
        ) : (
          <>
            <h2 className="text-center font-bold text-3xl">
              {t(i18nKeys.SEND_FEEDBACK)}
            </h2>

            <div className="flex-1 my-8">
              <TextArea
                autoFocus
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>

            <Button
              text={t(i18nKeys.SEND)}
              disabled={!text}
              onClick={() => {
                Axios.post(
                  'https://hooks.slack.com/services/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',
                  JSON.stringify({ text }),
                  {
                    withCredentials: false,
                    transformRequest: [
                      (data, headers) => {
                        delete headers.post['Content-Type'];
                        return data;
                      },
                    ],
                  }
                );
                setText('');
                setSent(true);
              }}
            />
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};
