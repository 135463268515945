import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const i18nKeys = {
  CREATE_ROOM: 'CREATE_ROOM',
  HAS_ENTERED_THE_CHAT: 'HAS_ENTERED_THE_CHAT',
  HAS_LEFT_THE_CHAT: 'HAS_LEFT_THE_CHAT',
  RULE: 'RULE',
  RULE_1: 'RULE_1',
  RULE_2: 'RULE_2',
  RULE_3: 'RULE_3',
  COPY_LINK_TO_CLIPBOARD: 'COPY_LINK_TO_CLIPBOARD',
  COPIED: 'COPIED',
  WRITE_A_MESSAGE: 'WRITE_A_MESSAGE',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  SEND: 'SEND',
  THANK_YOU_FOR_YOUR_FEEDBACK: 'THANK_YOU_FOR_YOUR_FEEDBACK',
  OK: 'OK',
};

const resources = {
  en: {
    translation: {
      CREATE_ROOM: 'Create Room',
      HAS_ENTERED_THE_CHAT: 'has entered the chat',
      HAS_LEFT_THE_CHAT: 'has left the chat',
      RULE: '< Chat Rules >',
      RULE_1:
        '1. Create your own chat room and invite friends by sharing the link.',
      RULE_2: '2. You can chat with your friends as a random K-pop idol.',
      RULE_3:
        "3. Try to figure out who's who. Everyone who joins the room turns into a random K-pop idol.",
      COPY_LINK_TO_CLIPBOARD: 'Copy chat link to clipboard',
      COPIED: 'Copied!',
      WRITE_A_MESSAGE: 'Write a message...',
      SEND_FEEDBACK: 'Send feedback',
      SEND: 'Send',
      THANK_YOU_FOR_YOUR_FEEDBACK: 'Thank you for your feedback',
      OK: 'OK',
    },
  },
  'ko-KR': {
    translation: {
      CREATE_ROOM: '방 만들기',
      HAS_ENTERED_THE_CHAT: '님이 입장하셨습니다',
      HAS_LEFT_THE_CHAT: '님이 나가셨습니다',
      RULES: '< 채팅방 룰 >',
      RULE_1:
        '1. 채팅방의 링크를 친구에게 보내보세요. 친구가 채팅방에 들어올 수 있습니다!',
      RULE_2: '2. 랜덤 아이돌로 입장한 친구들과 채팅해보세요.',
      RULE_3: '3. 채팅을 하며 서로가 누구인지 맞춰보세요.',
      COPY_LINK_TO_CLIPBOARD: '클립보드에 채팅 링크 복사',
      COPIED: '복사됨!',
      WRITE_A_MESSAGE: '메시지를 입력하세요...',
      SEND_FEEDBACK: '피드백 보내기',
      SEND: '보내기',
      THANK_YOU_FOR_YOUR_FEEDBACK: '피드백 감사합니다',
      OK: '확인',
    },
  },
};

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
