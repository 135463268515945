import React, { FC } from 'react';

interface ButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

export const Button: FC<ButtonProps> = ({
  text,
  disabled = false,
  onClick,
}) => {
  return (
    <button
      className="h-14 rounded-full bg-brand-1 text-white
                 disabled:bg-gray-100 disabled:text-gray-300"
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
