import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SendIcon } from '../../assets/images/icon-button-send.svg';
import { i18nKeys } from '../../i18n';

interface ChatInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSend: () => void;
}

export const ChatInput: FC<ChatInputProps> = ({ value, onChange, onSend }) => {
  const { t } = useTranslation();

  return (
    <form
      className="fixed bottom-0 flex items-center p-2 w-full h-fixed-bottom"
      onSubmit={(e) => {
        e.preventDefault();
        onSend();
      }}
    >
      <input
        className="flex-1 px-4 h-10
                   rounded-full border border-gray-200 bg-gray-50 text-gray-800
                   focus:border-brand-1 placeholder-gray-400"
        placeholder={t(i18nKeys.WRITE_A_MESSAGE)}
        type="text"
        autoFocus
        value={value}
        onChange={onChange}
      />
      <button
        className="flex justify-center items-center ml-2 w-8 h-8 rounded-full bg-brand-1"
        onClick={onSend}
      >
        <SendIcon />
      </button>
    </form>
  );
};
