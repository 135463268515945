import React, { ChangeEvent, FC } from 'react';

interface TextAreaProps {
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: FC<TextAreaProps> = ({
  label,
  placeholder,
  autoFocus = false,
  value,
  onChange,
}) => {
  return (
    <textarea
      id={label}
      className="block pt-3 px-4 w-full h-52
                 border border-gray-400 rounded-md shadow-sm sm:text-sm
                 focus:border-brand-1 placeholder-gray-400"
      placeholder={placeholder}
      autoFocus={autoFocus}
      value={value}
      onChange={onChange}
    />
  );
};
