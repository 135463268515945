import { Types } from 'ably';
import React, { FC } from 'react';
import { findCharacter } from '../../assets/data/characters';

interface MessageProps {
  message: Types.Message;
}

export const Message: FC<MessageProps> = ({ message }) => {
  const clientId = localStorage.getItem('clientId');
  const character = findCharacter(message.data.character);
  const isMine = message.clientId === clientId;
  const flexDirection = isMine ? 'flex-row-reverse' : 'flex-row';
  const gap = isMine ? 'ml-3' : 'mr-3';
  const backgroundColor = isMine ? 'bg-brand-1' : 'bg-gray-100';
  const color = isMine ? 'text-white' : 'text-gray-800';

  return (
    <div className={`flex ${flexDirection} items-start my-1`}>
      <div className={`flex flex-col items-center ${gap}`}>
        <img className="w-11 h-11" src={character.avatar} alt="avatar" />
        <span className="font-medium text-xs">{character.name}</span>
      </div>

      <div
        className={`px-4 py-2 min-w-0 rounded-3xl
                    ${backgroundColor} break-words text-lg ${color}`}
      >
        {message.data.text}
      </div>
    </div>
  );
};
