import { nanoid } from 'nanoid';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../components.tsx/Button';
import { i18nKeys } from '../i18n';

export const HomePage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Div100vh className="flex flex-col p-4">
      <div className="flex-1 flex justify-center items-center">
        <h1 className="font-bold text-4xl">DolChat</h1>
      </div>

      <Button
        text={t(i18nKeys.CREATE_ROOM)}
        onClick={() => history.push(`/chat?id=${nanoid(6)}`)}
      />
    </Div100vh>
  );
};
