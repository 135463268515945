import { Types } from 'ably';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { realtime } from '../../ably';
import { myCharacter, findCharacter } from '../../assets/data/characters';
import { ReactComponent as FeedbackIcon } from '../../assets/images/icon-top-navbar-feedback.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/icon-top-navbar-home.svg';
import { FeedbackDialog } from '../../components.tsx/FeedbackDialog';
import { UrlCopyButton } from '../../components.tsx/UrlCopyButton';
import { db, toDoc } from '../../firebase';
import { i18nKeys } from '../../i18n';
import { SystemMessage } from '../../models/SystemMessage';
import { MessageType } from '../../types';
import { devlog } from '../../utils';
import { ChatInput } from './ChatInput';
import { Message } from './Message';
import { SystemMessageItem } from './SystemMessageItem';

export const ChatPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [channelName, setChannelName] = useState('');
  const [channel, setChannel] = useState<Types.RealtimeChannelCallbacks>();
  const [members, setMembers] = useState<string[]>([]);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [character, setCharacter] = useState('');
  const [text, setText] = useState('');
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  useEffect(() => {
    if (!channelName) {
      const queryParams = queryString.parse(search);
      const chName = `public:${queryParams.id}`;
      setChannelName(chName);
      return;
    }

    if (!channel) {
      const ch = realtime.channels.get(channelName);
      ch.subscribe(channelName, (message) => {
        devlog(message);
        db.collection('messages').doc(message.id).set(toDoc(message));
        setMessages((prev) => [...prev, message]);
      });
      ch.presence.subscribe((presence) => {
        devlog(presence);
        db.collection('presences').doc(presence.id).set(toDoc(presence));
        const character = findCharacter(presence.data.character);
        switch (presence.action) {
          case 'enter':
            setMembers((prev) => [...prev, presence.data.character]);
            setMessages((prev) => [
              ...prev,
              new SystemMessage(
                `${character.name} ${t(i18nKeys.HAS_ENTERED_THE_CHAT)}`
              ),
            ]);
            break;
          case 'leave':
            setMembers((prev) =>
              prev.filter((m) => m !== presence.data.character)
            );
            setMessages((prev) => [
              ...prev,
              new SystemMessage(
                `${character.name} ${t(i18nKeys.HAS_LEFT_THE_CHAT)}`
              ),
            ]);
            break;
        }
      });
      ch.presence.get((_, members) => {
        setMembers(members?.map((member) => member.data.character) ?? []);
        const _character = myCharacter(members ?? []);
        ch.presence.enter({
          channel: channelName,
          character: _character,
        });
        setCharacter(_character);
      });
      setChannel(ch);
      return;
    }

    return () => {
      channel.presence.leave();
      channel.unsubscribe(channelName);
      channel.presence.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName, channel]);

  useEffect(() => {
    animateScroll.scrollToBottom({ duration: 100 });
  }, [messages]);

  return (
    <>
      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
      />

      <div className="sticky top-0 flex items-center px-2 h-12 border-b bg-white">
        <div className="flex-1 flex">
          <button
            className="flex justify-center items-center w-10 h-10"
            onClick={() => window.open('/', '_blank')}
          >
            <HomeIcon />
          </button>
        </div>

        <div className="flex">
          {members.map((member) => (
            <img
              key={member}
              className="mx-1 w-10 h-10"
              src={findCharacter(member).avatar}
              alt="avatar"
            />
          ))}
        </div>

        <div className="flex-1 flex flex-row-reverse">
          <button
            className="flex justify-center items-center w-10 h-10"
            onClick={() => setFeedbackDialogOpen(true)}
          >
            <FeedbackIcon />
          </button>
        </div>
      </div>

      <div className="flex flex-col mb-fixed-bottom p-4">
        <div className="font-semibold text-xs text-gray-400">
          <p>{t(i18nKeys.RULE)}</p>
          <p>{t(i18nKeys.RULE_1)}</p>
          <p>{t(i18nKeys.RULE_2)}</p>
          <p>{t(i18nKeys.RULE_3)}</p>
        </div>
        <UrlCopyButton />
        {messages.map((message, index) =>
          message instanceof SystemMessage ? (
            <SystemMessageItem
              key={`system:${index}`}
              systemMessage={message}
            />
          ) : (
            <Message key={message.id} message={message} />
          )
        )}
      </div>

      <ChatInput
        value={text}
        onChange={(e) => setText(e.target.value)}
        onSend={() => {
          if (text) {
            channel?.publish(channelName, {
              channel: channelName,
              character,
              text,
            });
            setText('');
          }
        }}
      />
    </>
  );
};
