import { Realtime } from 'ably';
import { nanoid } from 'nanoid';

const key = 'CV_uHw.WH9lFA:bGYVboS7ouuM4VD5';
let clientId = localStorage.getItem('clientId');
if (!clientId) {
  clientId = nanoid();
  localStorage.setItem('clientId', clientId);
}

export const realtime = new Realtime({ key, clientId });
